.App {
  min-height: 100vh;
  border-top: 1px solid #2886a0;
}

.Home {
  background-image: linear-gradient(to top, rgba(117, 117, 100, 0.9), rgba(58, 58, 156, 0.5)), 
                    url("../src/images/car.jpg");
  background-size: 100% 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(6px + 3vmin);
  color: rgb(45, 72, 102);
  padding-left: 5px;
  /* margin-top: 0%; */
  border-bottom: lightgray;
}

.Home h4 {
  margin-top: -20px;;
}

.Home-link {
  color: #2c534b;
  font-size: 0.7em;
}

.Home p {
  margin-top: -10px;;
}

.Profile-pic {
  margin-top: 8%;
  /* margin-bottom: 2%; */
  height: 50vmin;
  pointer-events: none;
}

.About {
  background-color: lightgray;
  min-height: 100vh;
  border-top: 1px solid #2886a0;
}

.About h3 {
  padding-left: 30px;
  padding-top: 4%;
}

.Projects {
  background-color: rgb(212, 198, 198);
  min-height: 100vh;
  border-top: 1px solid #2886a0;
}

.Projects h2 {
  padding-left: 30px;
  padding-top: 4%;
}

.Referrals {
  background-color: rgb(185, 169, 169);
  min-height: 100vh;
  border-top: 1px solid #2886a0;
}

.Referrals h2 {
  padding-left: 30px;
  padding-top: 4%;
}

.Trips {
  background-color: rgb(146, 137, 137);
  min-height: 100vh;
  border-top: 1px solid #2886a0;
}

.Trips h2 {
  padding-left: 30px;
  padding-top: 4%;
}